/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background: linear-gradient(45deg, #0d0208, #003b00, #008f11, #00ff41);
    background-size: 400%;
    color: white; /* fallback color */
    @apply text-white text-base py-10 bg-fixed bg-no-repeat animate-gradient-shift;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  .avatar-container {
    @apply lg:w-28 md:w-24 w-1/4 mx-auto rounded-full relative;
  }

  .avatar-placeholder {
    @apply w-full h-0 rounded-full;
    padding-bottom: 100%;
  }

  .avatar {
    @apply rounded-full absolute top-0 left-0;
  }

  .title {
    @apply text-center py-5;
  }

  .link-container {
    @apply 2xl:w-1/3 xl:w-1/2 w-11/12 h-16 mb-3 overflow-hidden;
    @apply flex justify-center items-center relative;
    @apply border-solid border-2 cursor-pointer;
    @apply rounded-lg;
    transition: background-color 0.5s ease;
  }

  .link-container:hover {
    @apply mix-blend-screen;
    background-color: white; /* fallback color */
    color: black; /* fallback color */
    @apply bg-white text-black;
  }

  .link-container:hover .hover-uninvert {
    @apply invert-0;
  }

  .link-container:hover .hover-invert {
    @apply invert;
  }

  .link-logo {
    @apply absolute block w-auto h-8 left-3 object-contain;
    object-position: initial;
  }

  #links ul li {
    @apply flex justify-center items-center;
  }

  .hover-uninvert {
    @apply filter invert;
  }

  .hover-invert {
    @apply filter invert-0;
  }
}
